import { TableBody } from "@material-ui/core"
import { ComputerSharp } from "@material-ui/icons"

interface IRequestOptions<TBody> {
  body?: TBody
  method: string
}

function getCookie(name: string) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            // Does this cookie string begin with the name we want?
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}

export class ApiClient {
  baseUrl: string

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl
  }

  _buildUrl(endPoint: string): string {
    return this.baseUrl + endPoint
  }
    async request<TBody={}, TResponse={}>(endPoint: string, options: IRequestOptions<TBody>): Promise<TResponse> {
     const init: RequestInit = {
       method: options.method,
       body: options.body && JSON.stringify(options.body) || null,
       mode: 'cors',
       credentials: 'include',
       headers: {
         'X-CSRFToken': getCookie('csrftoken') || '',
         'Content-Type': 'application/json',
       },
     }
    return fetch(this._buildUrl(endPoint), init)
      .then(r => {
        if(!r.ok) return Promise.reject(r)
        return r
      })
      .then(r => r.json() as Promise<TResponse>)
  }

  get<TB = {}>(endpoint: string, options: Omit<IRequestOptions<TB>, 'method'> = {}) {
    return this.request(endpoint, {
      ...options,
      method: 'get',
    })
  }

  post<TB>(endpoint: string, body?, options: Omit<IRequestOptions<TB>, 'method'> = {}) {
    return this.request(endpoint, {
      ...options,
      body,
      method: 'post',
    })
  }

  put<TB>(endpoint: string, body?, options: Omit<IRequestOptions<TB>, 'method'> = {}) {
    return this.request(endpoint, {
      ...options,
      body,
      method: 'put',
    })
  }

}

class ApiBase {
  client: ApiClient

  constructor(client: ApiClient) {
    this.client = client
  }
}

class AccountsApi extends ApiBase {
  login(username: string, password: string) {
    return this.client.post('/dj-rest-auth/login/', {
      body: { username, password }
    })
  }

  logout() {
    return this.client.post('/dj-rest-auth/logout/')
  }

  getIsLoggedIn() {
    return this.client.get('/api/isloggedin/')
  }

  getMyProfile() {
    return this.client.get('/api/accounts/myprofile/')
  }
}

export const _client = new ApiClient(process.env.GATSBY_API_URL || '')

export const accounts = new AccountsApi(_client)
