import React from 'react'
import styled from 'styled-components/macro'

import CircularProgress from '@material-ui/core/CircularProgress'
import { Typography } from '@material-ui/core'

const FullPageCenterContainer = styled.div`
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  background: white;
  z-index: 100;
`
export function FullPageSpinner() {
  return (
    <FullPageCenterContainer>
      <CircularProgress size="4rem"/>
      <Typography>LOADING</Typography>
    </FullPageCenterContainer>
  )
}
