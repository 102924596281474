import styled from 'styled-components'

export const Form = styled('form')`
  // margin-top: 1rem;
`

export const FormRow = styled.div`
  margin-top: 1rem;
`

export const SubmitRow = styled(FormRow)`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`
