import React, { useCallback, useState } from "react"
import styled from "styled-components"
import { Link } from "@reach/router"

import Box from "@material-ui/core/Box"
import AppBar from "@material-ui/core/AppBar"
import ToolBar from "@material-ui/core/Toolbar"
import Typography from "@material-ui/core/Typography"
import IconButton from "@material-ui/core/IconButton"
import MenuIcon from "@material-ui/icons/Menu"
import BackIcon from "@material-ui/icons/ArrowBack"

import { Avatar, Menu, MenuItem } from "@material-ui/core"

import { useLogout, useMyProfile } from "src/utils/hooks"

const MenuContent = styled.div`
  flex-grow: 1;
`

const StyledLink = styled(Link)`
  color: #fff;
`

interface PageProps {
  title: string
  backLink?: string
}

function BackButton({ to }: { to: string }) {
  return (
    <StyledLink to={to}>
      <IconButton edge="start" color="inherit">
        <BackIcon />
      </IconButton>
    </StyledLink>
  )
}

export const Page: React.FC<PageProps> = function Page(props) {
  const logout = useLogout()
  const profile = useMyProfile()

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleLogoutClick = () => {
    logout()
  }

  const handleClickProfile = useCallback((e: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget)
  }, [])

  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  return (
    <Box>
      <AppBar position="static" color="primary">
        <ToolBar variant="dense">
          {props.backLink ? (
            <BackButton to={props.backLink} />
          ) : (
            <IconButton edge="start" color="inherit">
              <MenuIcon />
            </IconButton>
          )}
          <MenuContent>
            <Typography variant="h6" color="inherit">
              {props.title}
            </Typography>
          </MenuContent>
          <Avatar
            onClick={handleClickProfile}
            src={`https://www.gravatar.com/avatar/${
              profile?.gravatar_hash || ""
            }`}
          />
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={open}
            onClose={handleClose}
          >
            <MenuItem onClick={handleLogoutClick}>Logout</MenuItem>
          </Menu>
        </ToolBar>
      </AppBar>
      {props.children}
    </Box>
  )
}
