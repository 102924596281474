import React, { useCallback } from 'react'
import { RouteComponentProps } from '@reach/router';
import styled, { css } from 'styled-components/macro'
import { useForm } from 'react-hook-form'
import { useMutation, useQueryCache } from 'react-query'

import { palette, PaletteProps, } from '@material-ui/system'

import Container from '@material-ui/core/Container'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'

import { login, MY_PROFILE_KEY } from 'src/api/queries/accounts'

import {} from '@material-ui/icons'
import { useIsAuthenticated } from 'src/utils/hooks';

const FullpageCenter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100vw;
  height: 100vh;
`

const LoginContainer = styled(Paper)<PaletteProps>`
  width: 95%;
  max-width: 25rem;
  padding-bottom: 2rem;

  ${palette}
`

const LoginHeader = styled(Box)`
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  text-align: center;
  padding: 2rem;
`

const InputRow = styled.div<{variant?: string}>`
  margin-top: 1rem;

  ${({variant}) => variant === "right" && css`
    text-align: right;
  `}

`

const SplitterLine = styled.div`
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 2rem;
  text-align: center;
  border-bottom: 2px solid ${({theme}) => theme.palette.primary.light };
  line-height: 0.1em;
`

const SplitterText = styled.span`
  background: #fff;
  padding: 0 1rem;
  color: ${({theme}) => theme.palette.primary.light };
  font-weight: bold;
`

const Splitter: React.FC = function Splitter({children}) {
  return (
    <SplitterLine><SplitterText>{children}</SplitterText></SplitterLine>
  )
}


export function LoginPage(props: RouteComponentProps) {
  const { register, handleSubmit, errors } = useForm()
  const queryCache = useQueryCache()
  const [loginMutation] = useMutation(login, {
    onSuccess: () => {
      queryCache.invalidateQueries(MY_PROFILE_KEY)
    }
  })

  const [isAuthenticated, isLoading] = useIsAuthenticated()
  console.log(isAuthenticated)

  const onSubmit = useCallback((data) => {
    loginMutation({username: data.username, password: data.password})
  }, [])

  if (!isLoading && isAuthenticated) {
    props.navigate && props.navigate('/overview')
    return null
  }

  return (
    <FullpageCenter>
      <LoginContainer>
        <LoginHeader color="primary.contrastText" bgcolor="primary.main" borderRadius="borderRadius">
          <Typography variant="h4">Talks - Login</Typography>
        </LoginHeader>
        <Container>
          <form onSubmit={handleSubmit(onSubmit)}>
            <InputRow>
              <TextField inputRef={register} name="username" label="Username" variant="outlined" fullWidth={true} />
            </InputRow>
            <InputRow>
              <TextField inputRef={register} name="password" label="Password" variant="outlined" fullWidth={true} type="password" />
            </InputRow>
            <InputRow variant="right">
              <Button type="submit" variant="contained" color="primary">Sign in</Button>
            </InputRow>
            {/*
            <Splitter> Or </Splitter>
            <InputRow>
              <Button variant="contained" fullWidth={true} >Sign in with google</Button>
            </InputRow>
            */}
          </form>
        </Container>
      </LoginContainer>
    </FullpageCenter>
  )
}
