import React from "react"
import { ReactQueryDevtools } from 'react-query-devtools'

import { FullPageSpinner } from '@components/spinner'

import { AppRouter } from 'src/routes'

import { useIsAuthenticated } from "src/utils/hooks"



function App() {
  const [, isLoading] = useIsAuthenticated()

  return (
    <>
      {isLoading ? <FullPageSpinner /> : <AppRouter />}
      <ReactQueryDevtools />
    </>
  )
}

export default function Index() {
  return <React.StrictMode><App /></React.StrictMode>
}
// export default function Home() {
//   return <div>Hello world!</div>
// }
