import React from "react"
import { RouteComponentProps } from "@reach/router"

import { Page } from "src/components/layout/page"

export function PlanningPage(pros: RouteComponentProps) {
  return (
    <Page title="Planning">
      <div>Planning!</div>
    </Page>
  )
}
