import { _client } from "../api"

interface HistoryItem {
    date: string
    speaker_name: string
    speaker_link: number
}
interface SpeakerData {
  id: number
  name: string
  phone: string
  congregation: number
  active: boolean
  talks: number[]
}

export interface TalkData {
  id: number
  name: string
  number: string
  available: boolean
  note: string
  history: HistoryItem[]
  speakers: number[]
}

export function fetchSpeaker(key: string, id: string): Promise<SpeakerData> {
  return _client.get<SpeakerData[]>(`/api/speakers/${id}/`) as any
}

export function fetchTalks(key: string): Promise<TalkData[]>{
  return _client.get<TalkData[]>(`/api/talkoutlines/`) as any
}
