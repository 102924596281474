import React, { useCallback, useEffect, useMemo, useState } from "react"
import styled from "styled-components/macro"
import { format, parseISO } from "date-fns"

import { useTalkList } from "src/utils/hooks"
import TextField from "@material-ui/core/TextField"
import Autocomplete from "@material-ui/lab/Autocomplete"
import CircularProgress from "@material-ui/core/CircularProgress"
import { TalkData } from "src/api/queries/speakers"

interface TalkSelectProps {
  speakerId?: number
  onChange: (v: { name: string; talkId?: number }) => void
  talkId?: number
  talkName: string
}

const OptionContainer = styled.div`
  width: 100%;
  border-bottom: 1px solid #ccc;
  padding-bottom: 0.2rem;
`
const OptionLabel = styled.div``
const History = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex-wrap: wrap;
`
const HistoryItem = styled.div<{ highlight: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1.5rem;
  border-radius: 0.75rem;
  padding: 0.2rem 0.5rem;
  margin-left: 0.3rem;
  margin-top: 0.3rem;
  border: 1px solid;
  border-color: ${({ highlight, theme }) =>
    highlight ? theme.palette.warning.main : "#ccc"};
  background-color: ${({ highlight, theme }) =>
    highlight ? theme.palette.warning.main : "inherit"};
`

function Option({
  option,
  speakerId,
}: {
  option: TalkData
  speakerId?: number
}) {
  const history = option.history
    .map(({ date, speaker_link }) => ({ date: parseISO(date), speaker_link }))
    .sort((a, b) => b.date.getTime() - a.date.getTime())
    .map(({ date, speaker_link }) => ({
      date: format(date, "MMM.Y"),
      isCurrent: speaker_link === speakerId,
      speaker_link,
    }))
  return (
    <OptionContainer>
      <OptionLabel>
        {option.number}. {option.name}
      </OptionLabel>
      <History>
        {history.map(({ date, isCurrent, speaker_link }) => (
          <HistoryItem key={date} highlight={isCurrent}>
            {date}
          </HistoryItem>
        ))}
      </History>
    </OptionContainer>
  )
}

export function TalkSelect(props: TalkSelectProps) {
  const { data, isLoading } = useTalkList()
  const options: TalkData[] = useMemo(() => {
    // return data || []
    const talks =
      (props.speakerId
        ? data?.filter(t => t.speakers.includes(props.speakerId || 0))
        : data) || []

    return talks.sort((a, b) => parseInt(a.number) - parseInt(b.number))
  }, [data])
  const [value, setValue] = useState<TalkData | null>(null)

  useEffect(() => {
    const { talkId } = props
    const v = options.find(td => td.id === talkId) || null
    setValue(v)
  }, [props.talkId, options])

  const onChange = useCallback((e, v) => {
    props.onChange({ name: v.name, talkId: v.id })
  }, [])

  return (
    <Autocomplete
      onChange={onChange}
      value={value}
      options={options}
      getOptionSelected={(option, value) => value && option.id === value.id}
      getOptionLabel={(option: TalkData) => `${option.number}. ${option.name}`}
      renderOption={option => (
        <Option option={option} speakerId={props.speakerId} />
      )}
      renderInput={params => (
        <TextField
          {...params}
          label="Theme"
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  )
}
