import { _client } from "../api"
import { formatISO, addDays } from "date-fns"

export interface FetchScheduleParams {
  after?: string
  before?: string
  cursor?: string
}

export interface PaginatedResults<T> {
  next: string | null
  previous: string | null
  results: T[]
}

export interface ScheduledTalkData {
  id: number
  date: string
  talk_name: string
  speaker_name: string
  speaker_phone: string
  speaker_congregation: string
  notes: string
  disabled: boolean
  speaker_link: number
  talk_link: number
}

export function fetchSchedule(
  key: string,
  params: FetchScheduleParams = {}
): Promise<PaginatedResults<ScheduledTalkData>> {
  const today = new Date(Date.now())
  const monday = addDays(today, -((today.getDay() + 6) % 7) - 1)
  console.log(monday)
  const isoDay = formatISO(monday, { representation: "date" })
  const { after, before, cursor = btoa(`p=${isoDay}`) } = params

  const urlParams = []
  urlParams.push(["cursor", cursor])
  if (after) urlParams.push(["after", after])
  if (before) urlParams.push(["before", before])

  const search = new URLSearchParams(urlParams).toString()
  const url = `/api/schedule/?${search}`

  return _client.get<PaginatedResults<ScheduledTalkData>>(url.toString()) as any
}

export function fetchScheduleItem(
  key: string,
  id: string
): Promise<ScheduledTalkData> {
  return _client.get<ScheduledTalkData>(`/api/schedule/${id}/`) as any
}

export function mutateScheduleItem(data: ScheduledTalkData) {
  return _client.put(`/api/schedule/${data.id}/`, data)
}
