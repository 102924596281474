import { useQuery, useMutation, useQueryCache } from 'react-query'
import {logout, fetchMyProfile, MY_PROFILE_KEY } from 'src/api/queries'
import { fetchTalks } from 'src/api/queries/speakers'

export function useIsAuthenticated(): [boolean, boolean] {
  const profileQuery = useQuery(MY_PROFILE_KEY, fetchMyProfile, {
    retry: 1,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  })
  return [profileQuery.isSuccess, profileQuery.isLoading]
}

export function useMyProfile() {
  const profileQuery = useQuery(MY_PROFILE_KEY, fetchMyProfile)

  return profileQuery.isSuccess ? profileQuery.data : null
}

export function useLogout() {
  const queryCache = useQueryCache()
  const [mutate] = useMutation(logout, {
    onSettled: () => {
      queryCache.clear()
      document.location = document.location
    }
  })
  return mutate
}

export function useTalkList() {
  return useQuery('talkOutlines', fetchTalks)
}
