import React from 'react'
import { Router, Redirect, RouteComponentProps } from "@reach/router"

import { LoginPage } from "@components/pages/login"
import { PlanningPage } from "@components/pages/planning"
import { OverviewPage } from "@components/pages/overview"
import { ScheduleDetailPage } from '@components/pages/schedule-detail'

import { useIsAuthenticated } from "src/utils/hooks"

const TYPES = {
  public: "public",
  private: "private",
  redirect: "redirect",
} as const

type TRouteType = keyof typeof TYPES

const BASE_PATH = "/"

export const ROUTES = [
  { name: "root", path: "/", type: TYPES.redirect, to: "/overview" },
  { name: "login", path: "/login", type: TYPES.public, component: LoginPage },
  {
    name: "planning",
    path: "/planning",
    type: TYPES.private,
    component: PlanningPage,
  },
  {
    name: "overview",
    path: "/overview",
    type: TYPES.private,
    component: OverviewPage,
  },
  {
    name: "scheduleDetail",
    path: "/overview/:scheduleId",
    type: TYPES.private,
    component: ScheduleDetailPage,
  }

]


interface IPrivateRouteProps extends RouteComponentProps {
  component: any
}

function PrivateRoute(props: IPrivateRouteProps) {
  const [isAuthenticated, isLoading] = useIsAuthenticated()

  if (isLoading) {
    return null
  }

  if (!isAuthenticated && location?.pathname !== "/login") {
    props.navigate && props.navigate("/login")
    return null
  }

  const { component: Component } = props
  return <Component {...props} />
}

interface RouteWrapperProps {
  path: string
  type: TRouteType
  component?: any
  to?: string
}

function RouteWrapper({
  path,
  type,
  component: Component,
  to,
  ...rest
}: RouteWrapperProps) {
  if (type === TYPES.redirect && to === undefined)
    throw new Error('redirect must have "to" parameter')
  if (type !== TYPES.redirect && Component === undefined)
    throw new Error("route must have a component")

  return type === TYPES.redirect ? (
    <Redirect path={path} to={to} {...rest} />
  ) : type === TYPES.private ? (
    <PrivateRoute path={path} component={Component} {...rest} />
  ) : (
    <Component path={path} {...rest} />
  )
}

export function AppRouter() {
  return (
    <Router basepath={BASE_PATH}>
      {ROUTES.map(route => (
        <RouteWrapper key={route.name} {...route} />
      ))}
    </Router>
  )
}
