import React from "react"
import styled from "styled-components"
import { useInfiniteQuery, useMutation, useQueryCache } from "react-query"
import { RouteComponentProps } from "@reach/router"

import { Container, Card, Paper, Typography, Box } from "@material-ui/core"

import {
  fetchSchedule,
  PaginatedResults,
  ScheduledTalkData,
} from "src/api/queries/schedule"

import { Page } from "src/components/layout/page"
import { ScheduledTalkItem, ScheduledTalkList } from "./components"

const parseCursor = (url: string | null): string | null => {
  if (!url) return null
  const u = new URL(url)
  return u.searchParams.get("cursor")
}

const ContentBox = styled(Box)`
  margin-top: 1rem;
`

export function OverviewPage(props: RouteComponentProps) {
  const scheduleQuery = useInfiniteQuery("schedule", fetchSchedule, {
    getFetchMore: lastPage => parseCursor(lastPage.next),
  })

  const data = scheduleQuery.data || []
  const schedule = data.reduce(
    (acc: ScheduledTalkData[], cur: PaginatedResults<ScheduledTalkData>) => [
      ...acc,
      ...cur.results,
    ],
    []
  )

  return (
    <Page title="Overview">
      <ContentBox>
        <Typography variant="h5">Scheduled talks</Typography>

        <ScheduledTalkList>
          {schedule.map(item => (
            <ScheduledTalkItem
              key={item.id}
              item={item}
              onClick={id => props.navigate && props.navigate("" + id)}
            />
          ))}
        </ScheduledTalkList>
      </ContentBox>
    </Page>
  )
}
