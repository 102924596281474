import { _client } from "../api"

export const MY_PROFILE_KEY = "myProfile"

export function fetchMyProfile() {
  return _client.get("/api/accounts/myprofile/")
}

export function logout() {
  return _client.post("/dj-rest-auth/logout/")
}

export function login({
  username,
  password,
}: {
  username: string
  password: string
}) {
  return _client.post("/dj-rest-auth/login/", { username, password })
}
