import React, { useCallback, useRef } from "react"
import styled from "styled-components"
import { format, parseISO } from "date-fns"
import { Typography, Card, ButtonBase } from "@material-ui/core"
import { navigate } from "@reach/router"

import { ScheduledTalkData } from "src/api/queries/schedule"

interface ScheduledTalkItemProps {
  item: ScheduledTalkData
  onClick?: (id: number) => void
}

const ListItem = styled(Card)<{ disabled: boolean }>`
  margin: 0.5rem 0;
  padding: 0.2rem 0.5rem;
  /*border-bottom: 1px solid #ccc; */

  height: 6rem;

  opacity: ${({ disabled }) => (disabled ? "0.5" : "1")};

  /*
  &::last-child {
    border: none;
  }
  */
`

const TalkTitle = styled.span`
  font-weight: bold;
`

const StyledInfoItem = styled.div`
  display: inline-block;
  margin-right: 1rem;
`
const InfoItemTitle = styled.span`
  font-weight: bold;
  color: #444;
`

const InfoItemText = styled.span`
  color: #444;
  & > a {
    text-decoration: none;
    color: #444;
  }
`

const TitleLine = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0.2rem 0;
`

const InfoItem: React.FC<{ title: string }> = function InfoItem({
  title,
  children,
}) {
  return (
    <StyledInfoItem>
      <InfoItemTitle>{title}:</InfoItemTitle>&nbsp;
      <InfoItemText>{children}</InfoItemText>
    </StyledInfoItem>
  )
}

export function ScheduledTalkItem({ item, onClick }: ScheduledTalkItemProps) {
  const talkName = item.talk_name.trim()
  const date = parseISO(item.date)
  const dateString = format(date, "d.M")
  const phoneRef = useRef(null)

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      console.log(e.target, phoneRef.current)
      if (e.target === phoneRef.current) return
      onClick && onClick(item.id)
    },
    [onClick, item.id]
  )

  return (
    <ListItem disabled={item.disabled} onClick={handleClick}>
      <TitleLine>
        <TalkTitle> {talkName === "" ? "(No theme)" : talkName} </TalkTitle>
        <TalkTitle>{dateString}</TalkTitle>
      </TitleLine>

      {item.speaker_name.trim() !== "" && (
        <Typography>
          {item.speaker_name}
          {item.speaker_congregation !== "" && ", " + item.speaker_congregation}
        </Typography>
      )}
      <div>
        {item.speaker_phone.trim() !== "" && (
          <InfoItem title="Phone">
            <a ref={phoneRef} href={`tel:${item.speaker_phone}`}>
              {item.speaker_phone}
            </a>
          </InfoItem>
        )}
        {item.notes.trim() !== "" && (
          <InfoItem title="Note">{item.notes}</InfoItem>
        )}
      </div>
    </ListItem>
  )
}

export const ScheduledTalkList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`
