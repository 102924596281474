import React, { useCallback, useState } from "react"
import { Button, Container, TextField } from "@material-ui/core"

import { Page } from "src/components/layout/page"
import { TalkSelect } from "src/components/talk-select"
import { useQuery, useMutation, useQueryCache } from "react-query"
import {
  fetchScheduleItem,
  mutateScheduleItem,
  ScheduledTalkData,
} from "src/api/queries/schedule"
import { useParams } from "@reach/router"
import { fetchSpeaker } from "src/api/queries/speakers"
import { useTalkList } from "src/utils/hooks"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"

import { Form, FormRow, SubmitRow } from "./schedule-detail.styles"

const yupSchema = yup.object({
  // talk_name: yup.string().notRequired(),
  // talk_link: yup.string().notRequired(),
  speaker_name: yup.string().notRequired(),
  speaker_link: yup.string().notRequired(),
  speaker_phone: yup.string().notRequired(),
  speaker_congregation: yup.string().notRequired(),
  notes: yup.string().notRequired(),
})

type Schema = yup.InferType<typeof yupSchema>

function DetailPartial({
  item,
  onSave,
}: {
  item?: ScheduledTalkData
  onSave: (data: ScheduledTalkData) => void
}) {
  const { register, handleSubmit, watch, errors } = useForm({
    resolver: yupResolver(yupSchema),
  })
  const [talkName, setTalkName] = useState(item?.talk_name || '')
  const [talkId, setTalkId] = useState(item?.talk_link)

  const onSubmit = handleSubmit((data: Schema) => {
    onSave({
      ...data,
      date: item?.date,
      id: item?.id,
      talk_link: talkId,
      talk_name: talkName,
    } as ScheduledTalkData)
  })

  const onChangeTalk = useCallback(({name, talkId}) => {
    setTalkId(talkId)
    setTalkName(name)
    console.log(name, talkId)
  }, [])

  if (!item) return null
  return (
    <Container>
      <Form onSubmit={onSubmit}>
        <FormRow>
          <TalkSelect speakerId={item.speaker_link} onChange={onChangeTalk} talkName={talkName} talkId={talkId}/>
        </FormRow>
        <FormRow>
          <TextField
            label="Speaker"
            variant="outlined"
            name="speaker_name"
            disabled={true}
            defaultValue={item.speaker_name}
            inputRef={register}
            fullWidth={true}
          />
        </FormRow>
        <FormRow>
          <TextField
            label="Congregation"
            variant="outlined"
            name="speaker_congregation"
            disabled={true}
            defaultValue={item.speaker_congregation}
            inputRef={register}
            fullWidth={true}
          />
        </FormRow>
        <FormRow>
          <TextField
            label="Phone"
            variant="outlined"
            name="speaker_phone"
            disabled={true}
            defaultValue={item.speaker_phone}
            inputRef={register}
            fullWidth={true}
          />
        </FormRow>
        <FormRow>
          <TextField
            label="Notes"
            variant="outlined"
            name="notes"
            defaultValue={item.notes}
            multiline
            rows={4}
            inputRef={register}
            fullWidth={true}
          />
        </FormRow>
        <SubmitRow>
          <Button type="submit" variant="contained" color="primary">
            Save
          </Button>
        </SubmitRow>
      </Form>
    </Container>
  )
}
export function ScheduleDetailPage() {
  const { scheduleId } = useParams()

  const { data: schedule, status } = useQuery(
    ["scheduleItem", scheduleId],
    fetchScheduleItem
  )

  const queryCache = useQueryCache()
  const [mutate] = useMutation(mutateScheduleItem, {
    onSuccess: () => {
      queryCache.invalidateQueries(["scheduleItem", scheduleId])
      console.log("saved data")
    },
  })

  const onSave = useCallback(
    (data: ScheduledTalkData) => {
      mutate(data)
    },
    [mutate]
  )

  return (
    <Page backLink=".." title={`${schedule?.date || "..."}`}>
      {status === "loading" ? (
        <div>LOADING</div>
      ) : (
        <DetailPartial item={schedule} onSave={onSave} />
      )}
    </Page>
  )
}
